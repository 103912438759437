import Link from 'next/link';
import React from 'react';

export default function OpenCart({ quantity }: { quantity?: number }) {
  return (
    <div
      className={`relative flex h-11 w-11 items-center justify-center rounded-md  text-hue-black transition-colors dark:text-white ${
        quantity ? '' : 'text-opacity-30'
      }`}
    >
      <ul className="transition-all ease-in-out hover:scale-110">
        <Link
          href="/cart"
          style={{ pointerEvents: quantity ? 'all' : 'none' }}
          className={`text-xs decoration-0 underline-offset-4 hover:text-hue-black hover:underline dark:text-hue-white dark:hover:text-neutral-300`}
        >
          {(quantity && `BAG (${quantity})`) || `BAG`}
        </Link>
      </ul>
    </div>
  );
}
