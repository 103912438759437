import Image from 'next/image';

export default function LogoIcon({ variant }: { variant: string }) {
  return (
    <Image
      src={`/img/HUE_Wordmark_${variant}.png`}
      alt="Hue logo"
      width="90"
      height="64"
      style={{ paddingBottom: '5px', paddingTop: '5px' }}
    />
  );
}
